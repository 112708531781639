<template>
  <div class="container" :class="isWhatIf && `what-if`">
    <div class="modal" :class="modalClosed && `close`">
      <div class="preamble-section">
        <h1>WHAT IF?</h1>
        <h2>Imagine a World You Want to Live In</h2>
        <div
          class="tooltip-ziggy-arrow-down"
          role="presentation"
          alt="A squiggly downward-pointing arrow."
        ></div>
        <div class="instructions">
          <h4>INSTRUCTIONS</h4>
          <ol>
            <li>
              Tap the deck to shuffle until you encounter a card that speaks to
              you, one that might describe something about a world you'd want to
              live in.
            </li>
            <li>
              Play the audio guide track, and begin your
              <i>What If?</i> session.
            </li>
          </ol>
        </div>
        <div class="button" @mousedown="toggleModal()">Ok, Start!</div>
      </div>
    </div>
    <div class="single-container">
      <div class="card-section">
        <div>
          <!-- DISPLAY DECK ILLUSTRATION IF NOT YET TAPPED -->
          <div
            v-if="!userStarted"
            class="display-deck-art"
            :style="{
              backgroundColor: deckUnsorted[0].color1,
              color: deckUnsorted[0].color2,
            }"
            @mousedown="setUserStarted(true)"
          >
            <div class="cover-card-inner-border">
              <div class="feedback-loop-deck-logo" />
            </div>
          </div>
          <!-- DISPLAY HOLDING ZONE IF NO SELECTED CARD -->
          <div
            v-if="selectedCard === undefined && userStarted"
            class="display-loading"
          >
            <p>LOADING...</p>
          </div>
          <!-- DISPLAY SELECTED CARD -->
          <div
            v-if="userStarted"
            :key="deckUnsorted[selectedCard].id"
            class="single-card"
            :class="isWhatIf && `what-if`"
            :style="{
              backgroundColor: deckUnsorted[selectedCard].color1,
              color: deckUnsorted[selectedCard].color2,
            }"
            @mousedown="selectRandom()"
          >
            <div
              class="top"
              :style="{
                backgroundColor: deckUnsorted[selectedCard].color2,
                color: deckUnsorted[selectedCard].color1,
              }"
            >
              <p>{{ deckUnsorted[selectedCard].category }}</p>
            </div>
            <div class="bottom">
              <div
                v-if="deckUnsorted[selectedCard].svgURL"
                class="card-icon"
                :style="{
                  'background-image':
                    'url(' + deckUnsorted[selectedCard].svgURL + ')',
                }"
              ></div>
              <p>{{ deckUnsorted[selectedCard].text }}</p>
            </div>
          </div>
        </div>
        <!-- CONTROLS SECTION -->
        <div class="controls-section prevnext">
          <!-- PREV / NEXT CARD BUTTONS -->
          <div
            class="prevnext-btn"
            :class="noMorePrevCard && `inactive`"
            @mousedown="showPreviousCard()"
          >
            ←
          </div>
          <div
            class="prevnext-btn"
            :class="noMoreNextCard && `inactive`"
            @mousedown="showNextCard()"
          >
            →
          </div>
        </div>
        <div class="controls-section">
          <!-- OPEN INSTRUCTIONS MODAL BUTTON -->
          <div>
            <div class="round-button" @mousedown="toggleModal()">?</div>
          </div>
          <!-- AUDIO CONTROLLER -->
          <div>
            <audio controls preload="auto">
              <source src="@/assets/IIF_MoMA_Edit_03_v3.ogg" type="audio/ogg" />
              <source
                src="@/assets/IIF_MoMA_Edit_03_v3.mp3"
                type="audio/mpeg"
              />
              Your browser does not support the audio element.
            </audio>
          </div>
        </div>

        <!-- ORDER PRINTED DECK SECTION -->
        <div class="order-printed-section">
          <p>
            Printed decks can be ordered at the
            <a href="https://moreandmorestore.bigcartel.com/" target="_blank"
              >More&More&nbsp;Store</a
            >.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import { getDeck } from "@/services/CardsService.js";
// import Card from "@/components/Card.vue";

export default {
  name: "single-cardPicker",
  data() {
    return {
      modalClosed: false,
      userStarted: false,
      selectedCard: undefined,
      cardHistory: [],
      cardHistoryIndex: undefined,
      deckId: this.$route.params.deckId,
      deck: [
        {
          // Template of a Category
          id: String,
          name: String,
          color1: String,
          color2: String,
          cards: [
            // Template of a Card
            {
              id: String,
              text: String,
              dealt: Boolean,
            },
          ],
        },
      ],
      deckUnsorted: [
        {
          id: String,
          text: String,
          catId: String,
          category: String,
          color1: String,
          color2: String,
        },
      ],
      roleCategories: {},
      constraintCategories: {},
    };
  },
  computed: {
    isWhatIf() {
      return this.$route.params.deckId === `what if`;
    },
    noMorePrevCard() {
      if (this.cardHistoryIndex === 0 || this.cardHistory.length === 0) {
        return true;
      } else {
        return false;
      }
    },
    noMoreNextCard() {
      if (
        this.cardHistoryIndex === this.cardHistory.length - 1 ||
        this.cardHistory.length === 0
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  components: {
    // Card,
  },
  methods: {
    toggleModal() {
      this.modalClosed = !this.modalClosed;
    },
    fetchNewDeck() {
      getDeck(this.deckId)
        .then((theData) => {
          // this.setupCategoriesAndRoles(theData);
          this.deck = theData;
          this.placeIntoUnsortedDeck(theData);
          // console.log("Data keys: ", Object.keys(this.deck));
          // console.log(this.deck[Object.keys(this.deck)[2]].cards[0]);
        })
        .catch((err) => {
          console.log("Oh noes, something went wrong: ", err);
        });
    },
    placeIntoUnsortedDeck(cardData) {
      let cardsArray = [];
      for (const [key, data] of Object.entries(cardData)) {
        console.log(`${key}: ${data}`);
        for (const [, cardData] of Object.entries(data.cards)) {
          let card = {};
          card.catId = data.id;
          card.category = data.name ? data.name : `no name`;
          card.color1 = data.color1;
          card.color2 = data.color2;
          card.id = cardData.id;
          card.text = cardData.text;
          cardsArray.push(card);
        }
      }
      this.deckUnsorted = cardsArray;
      this.selectRandom();
      console.log(`Done! Total of ${this.deckUnsorted.length} cards.`);
    },
    selectRandom() {
      this.selectedCard = Math.floor(Math.random() * this.deckUnsorted.length);
      this.cardHistory.push(this.selectedCard);
      this.cardHistoryIndex = this.cardHistory.length - 1;
      // console.log(`Selected card is ${this.selectedCard}`);
      // console.log(`Card history: ${this.cardHistory}`);
    },
    setUserStarted(val) {
      this.userStarted = val;
    },
    showPreviousCard() {
      if (this.cardHistory.length === 0 || this.cardHistoryIndex === 0) {
        return;
      }
      this.cardHistoryIndex = this.cardHistoryIndex - 1;
      this.selectedCard = this.cardHistory[this.cardHistoryIndex];
    },
    showNextCard() {
      if (
        this.cardHistory.length === 0 ||
        this.cardHistory.length - 1 === this.cardHistoryIndex
      ) {
        return;
      }
      this.cardHistoryIndex = this.cardHistoryIndex + 1;
      this.selectedCard = this.cardHistory[this.cardHistoryIndex];
    },
  },
  mounted() {
    this.fetchNewDeck();
  },
};
</script>
<style scoped>
.container {
  /* disable double-tap zoom on iOS mobile  */
  touch-action: manipulation;
  min-height: 100vh;
}
.container.what-if {
  background-color: var(--var-whatIf-yellow);
}
.modal {
  position: fixed;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2.7rem;
  background-color: rgba(0, 0, 0, 0.5);
  height: calc(100vh - 8rem);
  width: 100%;
}
.modal.close {
  display: none;
}
.preamble-section {
  background-color: black;
  color: white;
  max-width: 450px;
  padding: 2rem 2rem 4rem 2rem;
  margin: 1rem 2rem 5rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
}
.preamble-section > h1 {
  font-size: 4rem;
  text-align: center;
  margin: 0;
  padding: 0;
}

.preamble-section > h2 {
  text-transform: uppercase;
  font-size: 1.8rem;
  font-weight: 100;
  text-align: center;
  margin: 0;
  padding: 0;
}
.preamble-section > .tooltip-ziggy-arrow-down {
  height: 5.66rem;
  width: 1rem;
  background-image: url(~@/assets/ZiggyZig.png);
  background-size: contain;
  background-repeat: no-repeat;
  margin: 2rem 0.8rem 0 0.8rem;
}
.preamble-section > .instructions {
  border: 2px solid white;
  margin: 0 0.75rem 2rem 0.75rem;
  padding: 1rem 2rem 1rem 3rem;
  /* min-width: 170px; */
}
.preamble-section > .instructions > h4 {
  font-family: var(--var-IIF-sans-heavy);
  text-align: center;
  font-size: 1.2rem;
}
.preamble-section > .instructions > ol {
  font-size: 1.8rem;
  padding-left: 0.5rem;
}
.preamble-section > .instructions > ol > li {
  font-family: var(--var-IIF-sans-body);
  font-size: 1.4rem;
  margin-bottom: 2rem;
}

.preamble-section .button {
  font-family: var(--var-IIF-sans-heavy);
  text-transform: uppercase;
  font-size: 1.4rem;
  font-weight: bold;
  cursor: pointer;
  background-color: white;
  color: #0044f2;
  padding: 1.5rem 2rem 1.1rem 2rem;
  margin: 0;
}
.preamble-section .button:hover {
  color: white;
  background-color: #0044f2;
}

.single-container {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  gap: 4rem;
  /* disable double-tap zoom on iOS mobile  */
  touch-action: manipulation;
}

/* CARD SECTION */
.card-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.display-deck-art {
  background-color: var(--var-whatIf-teal);
  border-radius: 2rem;
  margin: 2rem;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  height: 50rem;
  width: calc(50 / 32 * 22rem);
  min-height: 320px;
  min-width: 220px;
  max-width: 60vw;
  max-height: calc(60 / 22 * 33vw);
  > p {
    color: white;
    font-size: 2rem;
    text-transform: uppercase;
  }
}
.cover-card-inner-border {
  width: 90%;
  height: 93%;
  margin: 1rem;
  border-radius: 2rem;
  border: 2px solid var(--var-whatIf-yellow);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.feedback-loop-deck-logo {
  width: 70%;
  height: 70%;
  min-width: 160px;
  min-height: 160px;
  background-image: url(~@/assets/moreAndMoreLogoYellow-1000x1000.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.display-loading {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 1rem;
  margin: 2rem;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  height: 50rem;
  width: calc(50 / 32 * 22rem);
  min-height: 320px;
  min-width: 220px;
  max-width: 60vw;
  max-height: calc(60 / 22 * 33vw);
  > p {
    color: white;
    font-size: 2rem;
    text-transform: uppercase;
  }
}

.appear {
  animation-duration: 0.5s;
  animation-name: animate-fade;
  animation-delay: 0;
  animation-fill-mode: backwards;
}

@keyframes animate-fade {
  0% {
    opacity: 0.4;
    transform: scale(0.95, 0.95);
    transform: rotate(-3deg);
    transform-origin: 20% 100%;
  }
  100% {
    opacity: 1;
    transform: scale(1, 1);
    transform: rotate(0);
    transform-origin: 20% 100%;
  }
}

.single-card {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 50rem;
  width: calc(50 / 32 * 22rem);
  min-height: 320px;
  min-width: 220px;
  max-width: 60vw;
  max-height: calc(60 / 22 * 33vw);
  margin: 2rem;
  border-radius: 2rem;
  overflow: hidden;
  cursor: pointer;
}

.single-card > .top {
  min-height: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  > p {
    font-size: 3rem;
    text-transform: uppercase;
    text-align: center;
  }
}

.single-card > .bottom {
  display: flex;
  height: 80%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 2rem;
  > p {
    text-transform: uppercase;
    text-align: center;
    font-size: 2.7rem;
  }
}
/* STYLE OVERRIDES FOR WHAT IF? DECK */
.single-card.what-if > .top {
  display: none !important;
}
.single-card.what-if > .bottom {
  height: 100%;
  margin: 1rem;
  border-radius: 2rem;
  border: 2px solid var(--var-whatIf-yellow);
}

/* STYLES FOR CONTROLS AT SCREEN BOTTOM */

.controls-section {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  justify-content: center;
}
.controls-section.prevnext {
  width: 100%;
  justify-content: space-around;
}

/* BUTTONS */
.button-div {
  margin-top: 6rem;
  margin-bottom: 10rem;
}
button {
  font-family: var(--var-IIF-sans-bold);
  border: 0;
  background-color: var(--var-IIF-teal);
  color: var(--var-IIF-blue);
  text-transform: uppercase;
  font-weight: bolder;
  margin: 1rem;
  padding: 1.8rem 2.8rem 1.5rem 2.8rem;
  font-size: 1.4rem;
}
button:hover {
  transform: translate(0, -4px);
  box-shadow: 0 4px var(--var-IIF-blue);
}
button:active {
  /* transform: translate(0, 4px); */
  box-shadow: 0 -4px var(--var-IIF-blue);
  padding-bottom: calc(1.2rem - 4px);
  margin-top: calc(1rem + 4px);
}
button:disabled {
  opacity: 0.5;
}

.prevnext-btn {
  cursor: pointer;
  font-size: 5rem;
  line-height: 2rem;
  margin-bottom: 2.75rem;
  color: black;
}
.prevnext-btn:hover {
  color: white;
}
.prevnext-btn.inactive {
  opacity: 0.2;
  pointer-events: none;
}

.round-button {
  cursor: pointer;
  background-color: black;
  height: 3.3rem;
  width: 3.3rem;
  font-size: 3rem;
  line-height: 4.1rem;
  color: white;
  font-family: var(--var-IIF-sans-heavy);
  text-align: center;
  padding: 1rem;
  border-radius: 50%;
}
.round-button:hover {
  color: black;
  background-color: white;
}

.order-printed-section {
  box-sizing: border-box;
  font-size: 1.6rem;
  line-height: 1.3;
  font-family: var(--var-IIF-sans-bold);
  text-align: center;
}
.order-printed-section a,
.order-printed-section a:hover {
  font-weight: bold;
  color: black;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .preamble-section > .instructions > ol {
    padding-left: 2rem !important;
  }
  .single-card > .bottom {
    > p {
      font-size: 4rem !important;
    }
  }
  .single-card.what-if > .bottom {
    margin: 2rem;
  }
  .order-printed-cta {
    font-size: 1.8rem;
    /* border: 1px solid red; */
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .single-card > .top > p {
    font-size: 4rem !important;
  }
  .single-card > .bottom > p {
    font-size: 5.8rem !important;
  }
  .order-printed-section {
    padding: 2.5rem 2rem;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .single-container {
    flex-direction: row;
    padding-top: 2rem;
  }
  .order-printed-section {
    margin: 0;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
</style>
